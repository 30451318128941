<template>
  <div>
    <!-- <ayl-berad-nav :nav="nav"></ayl-berad-nav> -->
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          :label-width="$config.labelWidth"
        >
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="养护企业" prop="careUnitName">
                <el-input v-model="form.careUnitName" placeholder="请输入" maxlength="30" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="标段名称" prop="bidId">
                <el-cascader
                  v-model="form.bidId"
                  placeholder="请选择"
                  :options="bidList"
                  :show-all-levels="false"
                  filterable
                  @change="hanldBidId"
                  disabled
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="自查日期" prop="checkDate">
                <el-date-picker
                  v-model="form.checkDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="timestamp"
                  disabled
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col class="time-scoped" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="自查时间" required>
                <el-form-item style="margin: 0" prop="checkStartTime">
                  <el-time-select
                    placeholder="起始时间"
                    v-model="form.checkStartTime"
                    :picker-options="{
                        start: '00:00',
                        step: '00:05',
                        end: '23:55'
                      }"
                    disabled
                  ></el-time-select>
                </el-form-item>

                <el-form-item style="margin: 0" prop="checkEndTime">
                  <el-time-select
                    placeholder="结束时间"
                    v-model="form.checkEndTime"
                    :picker-options="{
                        start: '00:00',
                        step: '00:05',
                        end: '23:55',
                        minTime: form.checkStartTime
                      }"
                    disabled
                  ></el-time-select>
                </el-form-item>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="人员" prop="checkName">
                <el-input v-model="form.checkName" placeholder="请输入" maxlength="25" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="联系方式" prop="checkPhone">
                <el-input v-model="form.checkPhone" placeholder="请输入" maxlength="11" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="备注">
                <el-input v-model="form.checkRemark" placeholder="请输入" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-form-item label="自查结果资料" style="width: 100%">
              <ayl-upload-files
                v-model="checkDocUrls"
                :limit="3"
                :maxSize="10240"
                :accept="''"
                disabled
              ></ayl-upload-files>
            </el-form-item>

            <el-form-item label="自查整改情况" style="width: 100%">
              <ayl-upload-files
                v-model="checkReformUrls"
                :limit="3"
                :maxSize="10240"
                :accept="''"
                disabled
              ></ayl-upload-files>
            </el-form-item>

            <el-form-item label="自查照片" style="width: 100%">
              <ayl-img-upload
                v-model="seekList"
                :imgWidth="'130px'"
                :imgHeight="'130px'"
                :imgListLength="50"
                :isEdit="false"
              ></ayl-img-upload>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button v-if="0" type="success" class="btn-success-hw" @click="onSubmit">提交</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      debug: 0,
      nav: [{ name: "企业安全自查详情" }],
      bidList: [],
      submitLoading: false,
      form: {
        careUnitName: null, //养护企业
        bidId: null, //标段名称
        checkDate: null, //自查日期
        checkStartTime: null, //自查开始时间
        checkEndTime: null, // 自查结束时间
        checkName: null, //自查人员
        checkPhone: null, //自查人员联系方式
        checkRemark: null, //备注
        checkDocUrls: [], //自查结果资料
        checkReformUrls: [], //自查整改情况
        checkImageUrls: null //自查照片
      },
      // 自查照片
      seekList: [],
      // 自查结果资料
      checkDocUrls: [],
      // 自查整改情况
      checkReformUrls: [],
      // 必填字段校验
      rules: {
        careUnitName: {
          required: true,
          message: "请输入养护企业",
          trigger: "blur"
        },
        bidId: {
          required: true,
          message: "请选择标段",
          trigger: "change"
        },
        checkDate: {
          required: true,
          message: "请选择自查日期",
          trigger: "change"
        },
        checkStartTime: {
          required: true,
          message: "请选择自查时间",
          trigger: "change"
        },
        checkEndTime: {
          required: true,
          message: "请选择自查时间",
          trigger: "change"
        },
        checkName: {
          required: true,
          message: "请输入自查人员姓名",
          trigger: "blur"
        },
        checkPhone: {
          required: true,
          message: "请输入自查人员电话号码",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    hanldBidId(arr) {
      this.form.bidId = arr[1];
    },
    //关闭
    async onClose() {
      BUS.$emit(BUSEVENT.REFRESH_SAFETY_CHECK);
      this.$emit("onClose"); //关闭弹窗
      await this.$refs["form"].resetFields();
    },

    async submit() {
      let params = JSON.parse(JSON.stringify(this.form));
      params.checkDate = this.form.checkDate + "";
      params.checkDocUrls = JSON.stringify(this.checkDocUrls);
      params.checkReformUrls = JSON.stringify(this.checkReformUrls);
      params.checkImageUrls = this.seekList.join(",");

      await this.$api_hw.operationSafetyManagement_updateSelfCheckInfo({
        sanitationSafeCheckVO: params
      });
      await this.$notify({
        title: "成功",
        message: `${this.nav[0].name}成功`,
        type: "success"
      });

      await this.onClose();
    },
    async onSubmit() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        this.submitLoading = true;
        try {
          await this.submit();
        } catch (e) {
          console.log(e);
        } finally {
          this.submitLoading = false;
        }
      });
    }
  },
  async mounted() {
    //获取所属标段下拉
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});

    // 获取表单详情信息+数据绑定
    let params = {
      id: this.dataFromDialog.checkId
    };
    let formData = await this.$api_hw.operationSafetyManagement_getSelfCheckInfo(
      params
    );
    this.form = JSON.parse(JSON.stringify(formData));

    // 自查照片
    this.seekList = this.form.checkImageUrls
      ? [...this.form.checkImageUrls.split(",")]
      : [];

    // 自查结果资料
    let arr1 = JSON.parse(formData.checkDocUrls);
    this.checkDocUrls = arr1.map(item => {
      return {
        name: item.name,
        url: item.url
      };
    });

    // 自查结果资料
    let arr2 = JSON.parse(formData.checkReformUrls);
    this.checkReformUrls = arr2.map(item => {
      return {
        name: item.name,
        url: item.url
      };
    });
  }
};
</script>

<style lang='sass' scoped>
.content-box 
  padding: 0
/deep/ el-form
  display: flex;
  flex-wrap: wrap;
/deep/ .el-form-item
  display: flex;
  width: 100%;
/deep/ .el-cascader--mini,
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
  line-height: 32px;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
  line-height: 34px;
  
.time-scoped /deep/ .el-form-item--mini .el-form-item__content
  display: flex;

/deep/ .el-input.is-disabled .el-input__inner,
/deep/ .el-textarea.is-disabled .el-textarea__inner
  color: #526a75
</style>